import { get, post } from '@/api/index';
export const courseList = params => get(`/api/course/v1/search/course_list`, params);//  课程搜索
export const allinfo = params => get(`/api/infrastruct/v1/allinfo`, params);//  全部栏目
export const majorinfofirst = params => get(`/api/infrastruct/v1/majorinfofirst`, params);//  一级专业
export const majorinfo = params => get(`/api/infrastruct/v1/majorinfo`, params);//  一级专业

export const subjectinfo = params => get(`/api/infrastruct/v1/subjectinfo`, params);//  次级学科

export const faculty = params => get(`/api/infrastruct/v1/faculty`, params); // 科目


